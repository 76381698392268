*{
  box-sizing: border-box;
}

/* styles.css */
body {
  background-color: black;
  color: white;
  font-size: 30px; /* Adjust the size as needed */
  line-height: 1.25; /* Adjust line spacing as needed */
  font-family: "Lato",Arial,"Helvetica Neue",Helvetica,sans-serif;
  padding: 10px;
  margin-bottom: 75px;
}

h2 {
  margin-top: 15px;
}

.question {
  margin-bottom: 10px; /* Space between questions */
}

.answer-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.answer {
  display: block;
  margin: 10px 0;
  padding: 10px; /* Add padding for better spacing */
  border: 1px solid #ccc; /* Add a subtle border */
  border-radius: 5px; /* Optional: round the corners */
  cursor: pointer; /* Change the cursor on hover to indicate clickability */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  width: 60%;
}

.answer:hover {
  background-color: #f0f0f0; /* Change background color on hover */
  color: black;
}

.footer {
  display: flex;
  justify-content:center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
  right: 50%;
  transform: translateX(50%);
  padding: 15px 0px;
  background: black;
}

.footer-text {
  font-size: 30px;
  margin-right:20px;
}

.logo {
  height: 50px;
}

.team{
  font-size: 25px;
  margin-bottom: 20px;
}

.chevron {
  transition: transform 0.3s ease; /* Smooth transition for rotation */
  display: flex;
  align-items: center;
  font-size: 25px;
}

.chevron.upward {
  transform: rotate(180deg); /* Rotate chevron to point upwards */
}

.flex {
  display: flex;
  justify-content: center;
}

.item-list textarea{
  width: 300px;
  font-size: 20px;
  background-color: transparent;
  color: white;
}

.item-container{
  margin: 20px 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #333;
}

.item-title{
  max-width: 60%;
  padding-left: 15px;
  font-size: 25px;
  width: 100%;
}

.control-button-container{
  position: relative;
  left: 10px;
}

.control-button {
  background-color: transparent;
  border: none;
  color: white;
  margin: 0 10px;
  cursor: pointer;
  font-size: 20px;
}

.answer-list{
  margin-top: 20px;
  font-size: 20px;
}

.item-list-header{
  margin-bottom: 10px;
}

.answer.selected{
  background-color: white;
  color: black;
}

.admin-answer-container{
  justify-content: space-between;
  min-height: 50px;
  margin: 25px;
  align-items: center;
}

.answer-text {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px;
}

.answer-text input{
  background-color: transparent;
  color: white;
  font-size: 20px;
  border: 1px solid #555;
}

.answer-title{
  text-align: left;
}

.answer-list .add-entity{
  margin-top: 20px;
}

.add-entity{
  height: 50px;
  width: 150px;
  background: black;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  margin-left: 5px;
  margin-right: 5px;
}
.answer-form-inputs{
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 20px;
  font-size: 18px;
}

.team-check{
  align-items: flex-start;
}

.team-check input{
  margin-top: 5px;
  margin-right: 10px;
}

.team-weight{
  margin-left: 30px;
  margin-top: 10px;
}

.team-weight input {
  max-width: 10%;
  background: transparent;
  color: white;
  border: 1px solid white;
  height: 20px;
  padding-left: 5px;
  margin-left: 10px;
  position: relative;
  bottom: 2px;
}

.inactive-weight{
  display: none;
}

.top-button{
  width: 150px;
  height: 50px;
  background: transparent;
  border: 1px solid white;
  margin: 5px;
  font-size: 25px;
  color: white;
  border-radius: 5px;
}

.top-button.active{
  background: white;
  color: black;
}

.team-details{
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}

.team-details p{
  font-size: 20px;
}

.team-details p strong{
  font-size: 20px;
}

.team-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  font-size: 25px;
}

.team-form input{
  background-color: transparent;
  color: white;
  font-size: 20px;
  border: 1px solid #555;
  margin-bottom: 20px;
  width: 100%;
}

.team-form textarea{
  margin-bottom: 20px;
  width: 100%;
  height: 150px;
}

.modal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  color: white;
  padding: 35px 20px;
  overflow-y: scroll;
}

.modal .close{
  cursor: pointer;
  font-size: 50px;
  text-align: right;
  width: 100%;
  display: block;
  padding-right: 15px;
}

.modal h3{
  margin-top: 0;
}

.modal p {
  font-size: 25px;
}

.login-form {
  max-width: 300px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: black;
}

.login-form label {
  display: block;
  margin-bottom: 8px;
  font-size: 25px;
  color: white;
}

.login-form input[type="text"],
.login-form input[type="password"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-form  button.add-entity {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background: black;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid white;
}

.login-form  button.add-entity:hover {
  background: white;
  color: black;
}

/* Error message styling */
.login-form  p {
  color: red;
  margin-bottom: 20px;
}


@media (hover: none) and (pointer: coarse) {
  .answer:hover{
    background: black;
    color: white;
  }
}

@media (min-width: 720px) {
  .answer-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .admin-answer {
    width: 33%;
  }

  .admin-answer-container{
    margin: 0;
  }

  .answer-title{
    width: 100%;
    text-align: left;
  }

  .team-details p{
    max-width: 50%;
    width: 100%;
  }

  .top-button{
    width: 250px;
  }

  .team-form textarea{
    width: 67%;
  }

  .team-form input{
    width: auto;
  }
}